function getCookie(name) {
  const nameEQ = `${encodeURIComponent(name)}=`;
  const cookiesArray = document.cookie.split(";");

  for (let i = 0; i < cookiesArray.length; i++) {
    let cookie = cookiesArray[i].trim();

    if (cookie.indexOf(nameEQ) === 0) {
      return decodeURIComponent(cookie.substring(nameEQ.length));
    }
  }

  return null; // If the cookie is not found
}
function setSessionCookie(name, value) {
  // Set cookie with session expiration (no 'expires' or 'max-age')
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}; path=/; SameSite=Lax`;
}

export { setSessionCookie, getCookie };
