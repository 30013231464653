import "./App.css";
import "abcjs/abcjs-audio.css";
import { Button, Select } from "flowbite-react";
import { useState } from "react";
import GameModeOne from "./GameModeOne";
import GameModeTwo from "./GameModeTwo";
import GameModeThree from "./GameModeThree";
import GameModeFour from "./GameModeFour";

function App() {
  const [gameMode, setGameMode] = useState("");
  return (
    <div>
      <div className="flex justify-center my-5">
        {gameMode === "" ? (
          <Select
            value={gameMode}
            onChange={(e) => {
              setGameMode(e.target.value);
            }}
          >
            <option value="">Pasirinkite žaidimo lygį</option>
            <option>Lengvas (natomis)</option>
            <option>Lengvas (simboliais)</option>
            <option>Vidutinis (simboliais)</option>
            <option>Dvi natos (simboliais)</option>
          </Select>
        ) : (
          <Button
            onClick={() => {
              setGameMode("");
            }}
          >
            Grįžti į meniu
          </Button>
        )}
      </div>

      {gameMode === "Lengvas (natomis)" ? <GameModeOne /> : null}
      {gameMode === "Lengvas (simboliais)" ? <GameModeTwo /> : null}
      {gameMode === "Dvi natos (simboliais)" ? <GameModeThree /> : null}
      {gameMode === "Vidutinis (simboliais)" ? <GameModeFour /> : null}
    </div>
  );
}

export default App;
