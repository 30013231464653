import { useState, useEffect, useRef } from "react";
import abcjs from "abcjs";
import { Badge, Button, Progress } from "flowbite-react";
import { getCookie, setSessionCookie } from "./hsStorage";

function GameModeOne() {
  const [note, setNote] = useState("");
  const [answer, setAnswer] = useState(null);
  const [roundPlaying, setRoundPlaying] = useState(false);
  const [gamePlaying, setGamePlaying] = useState(false);
  const gamePlayingRef = useRef(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [score, setScore] = useState(0);
  const [gameTimeRemaining, setGameTimeRemaining] = useState(0);
  const [roundTimeRemaining, setRoundTimeRemaining] = useState(0);

  useEffect(() => {
    abcjs.renderAbc("paper", `X:1\n|${note}|`, {
      responsive: "resize",
      staffwidth: 100,
    });
  }, [note]);

  const noteImages = ["C", "D", "E", "F", "G", "A", "B"];
  const noteImages2 = ["c", "d", "e", "f", "g", "a", "b"];
  const noteNames = ["Do", "Re", "Mi", "Fa", "Sol", "La", "Si"];

  function handleGuess(guessedNote) {
    if (guessedNote === answer) {
      console.log("Correct");
      setScore((prevScore) => prevScore + 10);
      nextRound();
    } else {
      console.log("Incorrect");
      if (score > 0) setScore((prevScore) => prevScore - 5);
      setRoundPlaying(false);
      setShowAnswer(true);

      setTimeout(() => {
        nextRound();
      }, 500);
    }
  }

  function nextRound() {
    if (!gamePlayingRef.current) {
      return;
    }
    setRoundTimeRemaining(10);

    let answer_id = Math.floor(Math.random() * 7);
    while (answer_id === noteNames.indexOf(answer)) {
      answer_id = Math.floor(Math.random() * 7);
    }

    let lowercase = Math.floor(Math.random() * 2);
    if (lowercase) {
      setNote(noteImages2[answer_id]);
    } else {
      setNote(noteImages[answer_id]);
    }

    setRoundPlaying(true);
    setAnswer(noteNames[answer_id]);
    setShowAnswer(false);
  }

  function startGame() {
    setGamePlaying(true);
    gamePlayingRef.current = true;
    setGameTimeRemaining(60);
    setScore(0);
    nextRound();
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (gamePlaying) {
        if (gameTimeRemaining > 0) {
          if (roundPlaying) setGameTimeRemaining((prev) => prev - 1);
        } else {
          setGamePlaying(false);
          gamePlayingRef.current = false;
          setRoundPlaying(false);

          let lasths = getCookie("gamemodeonehs");
          if (!lasths || (lasths && lasths < score)) {
            setSessionCookie("gamemodeonehs", score);
          }
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  });
  useEffect(() => {
    const timer = setInterval(() => {
      if (gamePlaying && roundPlaying) {
        if (roundTimeRemaining > 0) {
          setRoundTimeRemaining((prev) => prev - 1);
        } else {
          handleGuess(0);
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  return (
    <>
      <div className="flex justify-center">
        <Badge color="indigo" size="" className="mx-1">
          Daugiausiai surinkta taškų: {getCookie("gamemodeonehs") ?? 0}
        </Badge>
      </div>
      <div className="flex justify-center">
        <Badge color="indigo" size="" className="mx-1">
          Taškai: {score}
        </Badge>
        <Badge color="indigo" size="" className="mx-1">
          Likęs laikas: {gameTimeRemaining} sek.
        </Badge>
        <Badge color="indigo" size="" className="mx-1">
          Likęs laikas šio spėjimo: {roundTimeRemaining} sek.
        </Badge>
      </div>
      <div className="flex justify-center">
        <div className="w-1/3 my-2">
          <Progress
            progress={(gameTimeRemaining / 60) * 100}
            color={
              gameTimeRemaining > 30
                ? "green"
                : gameTimeRemaining > 15
                ? "yellow"
                : "red"
            }
          />
        </div>
      </div>
      <div className="mx-auto w-96">
        <div id="paper"></div>
      </div>
      <div className="flex justify-around w-1/3 mx-auto">
        {noteNames.map((noteName) => (
          <Button
            onClick={() => {
              handleGuess(noteName);
            }}
            key={noteName}
            disabled={!roundPlaying}
            color={
              showAnswer
                ? noteName === answer
                  ? "success"
                  : "failure"
                : "blue"
            }
            className="mx-1"
          >
            {noteName}
          </Button>
        ))}
      </div>
      <div className="flex justify-center mt-10">
        {!gamePlaying ? (
          <Button
            onClick={() => {
              startGame();
            }}
          >
            Pradėti žaidimą
          </Button>
        ) : null}
      </div>
    </>
  );
}

export default GameModeOne;
