import { useState, useEffect, useRef } from "react";
import abcjs from "abcjs";
import { Badge, Button, Progress } from "flowbite-react";
import { getCookie, setSessionCookie } from "./hsStorage";
import { nanoid } from "nanoid";

function GameModeThree() {
  const [note, setNote] = useState("");
  const [answer, setAnswer] = useState(["a", "a"]);
  const [roundPlaying, setRoundPlaying] = useState(false);
  const [gamePlaying, setGamePlaying] = useState(false);
  const gamePlayingRef = useRef(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [score, setScore] = useState(0);
  const [gameTimeRemaining, setGameTimeRemaining] = useState(0);
  const [roundTimeRemaining, setRoundTimeRemaining] = useState(0);
  const [answerChoices, setAnswerChoices] = useState([]);

  useEffect(() => {
    abcjs.renderAbc("paper", `X:1\n|${note}|`, {
      responsive: "resize",
      staffwidth: 100,
    });
  }, [note]);

  const noteNames = ["Do", "Re", "Mi", "Fa", "Sol", "La", "Si"];
  const noteImages = ["C", "D", "E", "F", "G", "A", "B"];
  const noteImages2 = ["c", "d", "e", "f", "g", "a", "b"];

  function getRandomNoteIndex(prevNumber) {
    let randomNum;
    do {
      randomNum = Math.floor(Math.random() * 7);
    } while (randomNum === prevNumber);
    return randomNum;
  }

  function getTwoRandomNoteIndexes(prev1, prev2) {
    return `${noteNames[getRandomNoteIndex(prev1)]}${
      noteNames[getRandomNoteIndex(prev2)]
    }`;
  }

  function handleGuess(guessedNote) {
    if (guessedNote === answer) {
      console.log("Correct");
      setScore((prevScore) => prevScore + 10);
      nextRound();
    } else {
      console.log("Incorrect");
      if (score > 0) setScore((prevScore) => prevScore - 5);
      setRoundPlaying(false);
      setShowAnswer(true);

      setTimeout(() => {
        nextRound();
      }, 500);
    }
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Get a random index from 0 to i
      // Swap elements at indices i and j
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  function nextRound() {
    if (!gamePlayingRef.current) {
      return;
    }
    setRoundTimeRemaining(10);

    let answer_1_id = getRandomNoteIndex(noteImages.indexOf(answer[0]));
    let answer_2_id = getRandomNoteIndex(noteImages.indexOf(answer[1]));

    let lowercase_1 = Math.floor(Math.random() * 2);
    let note_1;
    if (lowercase_1) {
      note_1 = noteImages2[answer_1_id];
    } else {
      note_1 = noteImages[answer_1_id];
    }

    let lowercase_2 = Math.floor(Math.random() * 2);
    let note_2;
    if (lowercase_2) {
      note_2 = noteImages2[answer_2_id];
    } else {
      note_2 = noteImages[answer_2_id];
    }

    setNote(note_1 + note_2);

    let answerChoices_temp = [];
    answerChoices_temp.push(getTwoRandomNoteIndexes(answer_1_id, answer_2_id));
    answerChoices_temp.push(getTwoRandomNoteIndexes(answer_1_id, answer_2_id));
    answerChoices_temp.push(getTwoRandomNoteIndexes(answer_1_id, answer_2_id));
    answerChoices_temp.push(noteNames[answer_1_id] + noteNames[answer_2_id]);

    setAnswerChoices(shuffleArray(answerChoices_temp));

    setRoundPlaying(true);
    setAnswer(noteNames[answer_1_id] + noteNames[answer_2_id]);
    setShowAnswer(false);
  }

  function startGame() {
    setGamePlaying(true);
    gamePlayingRef.current = true;
    setGameTimeRemaining(60);
    setScore(0);
    nextRound();
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (gamePlaying) {
        if (gameTimeRemaining > 0) {
          if (roundPlaying) setGameTimeRemaining((prev) => prev - 1);
        } else {
          setGamePlaying(false);
          gamePlayingRef.current = false;
          setRoundPlaying(false);

          let lasths = getCookie("gamemodethreehs");
          if (!lasths || (lasths && lasths < score)) {
            setSessionCookie("gamemodethreehs", score);
          }
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  });
  useEffect(() => {
    const timer = setInterval(() => {
      if (gamePlaying && roundPlaying) {
        if (roundTimeRemaining > 0) {
          setRoundTimeRemaining((prev) => prev - 1);
        } else {
          handleGuess(0);
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  return (
    <>
      <div className="flex justify-center">
        <Badge color="indigo" size="" className="mx-1">
          Daugiausiai surinkta taškų: {getCookie("gamemodethreehs") ?? 0}
        </Badge>
      </div>
      <div className="flex justify-center">
        <Badge color="indigo" size="" className="mx-1">
          Taškai: {score}
        </Badge>
        <Badge color="indigo" size="" className="mx-1">
          Likęs laikas: {gameTimeRemaining} sek.
        </Badge>
        <Badge color="indigo" size="" className="mx-1">
          Likęs laikas šio spėjimo: {roundTimeRemaining} sek.
        </Badge>
      </div>
      <div className="flex justify-center">
        <div className="w-1/3 my-2">
          <Progress
            progress={(gameTimeRemaining / 60) * 100}
            color={
              gameTimeRemaining > 30
                ? "green"
                : gameTimeRemaining > 15
                ? "yellow"
                : "red"
            }
          />
        </div>
      </div>
      <div className="mx-auto w-96">
        <div id="paper"></div>
      </div>
      <div className="flex justify-around w-1/3 mx-auto">
        {answerChoices.map((noteName) => (
          <Button
            onClick={() => {
              handleGuess(noteName);
            }}
            key={nanoid()}
            disabled={!roundPlaying}
            color={
              showAnswer
                ? noteName === answer
                  ? "success"
                  : "failure"
                : "blue"
            }
          >
            {noteName}
          </Button>
        ))}
      </div>
      <div className="flex justify-center mt-10">
        {!gamePlaying ? (
          <Button
            onClick={() => {
              startGame();
            }}
          >
            Pradėti žaidimą
          </Button>
        ) : null}
      </div>
    </>
  );
}

export default GameModeThree;
